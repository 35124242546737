.navBar {
  background-color: #fff;
}

.homeMain {
  background-color: #fff;
  height: 75vh;
}

.verticalCenter {
  padding: 25vh;
}

.footer {
  background-color: #fff;
  height: "100%"
}

.about {
  background-color: #fff;
}